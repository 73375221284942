<template>
   <a-modal
    title="编辑打印机"
    :width="720"
    :visible="bian"
    :confirmLoading="confirmLoading"  
    @cancel="handleCancel"
   >
   <div class="total myForm">
     <a-spin :spinning="confirmLoading">
        <a-form :form="form" autocomplete="off">
          <div class="totalleft">
              <a-form-item label="打印机名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-decorator="['custom_name', {rules: [{required: true,  message: '请输入打印机名称'}]}]"
                />
              </a-form-item>
                 <a-form-item label="打印机" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-select           
                      style="width: 120px"
                      v-decorator="[
                        'printer_name',
                        { rules: [{ required: true, message: '请选择打印机'}] },
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                          :value="item.name"
                          v-for="(item,index) in printList"
                          :key="index"
                        >
                        {{item.name}}
                        </a-select-option>
                      
                  </a-select>
                </a-form-item>
                <a-form-item label="打印机类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-select           
                    style="width: 120px"
                    @change="handleChange"
                    v-decorator="[
                   'printer_type',
                   { rules: [{ required: true, message: '请输入打印机类型'}] },                    
                   ]"
                    placeholder="请选择"
                    >
                      <a-select-option value="0">
                        本地打印机
                      </a-select-option>
                      <a-select-option value="1">
                        网络打印机
                      </a-select-option>
                      <!-- <a-select-option value="2">             
                        云打印机
                      </a-select-option> -->
                  </a-select>       
                </a-form-item>
                <div v-if=" printerTypeID==='0'">
                  <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                    <a-radio-group
                      v-decorator="['printer_category', { rules: [{required: true,message: '请选择打印种类'}]}]"
                    >
                        <a-radio
                          v-for="(item, index) in PrinterCategory"
                          :key="index"
                          :value="item.value"
                        >
                        {{ item.name }}
                      
                        </a-radio>
                    </a-radio-group>
       
                  </a-form-item> 
                    <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select style="width: 120px" v-decorator="['width', {initialValue:'请选择',rules: [{required: true,message: '请选择纸张类型'}]}]">
                      <a-select-option value=58>
                        58mm
                      </a-select-option>
                      <a-select-option value=80>
                        80mm
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                  <a-switch
                  checked-children="是" 
                  un-checked-children="否"                  
                    v-decorator="['status',{initialValue:'checked',valuePropName: 'checked' }]"          
                    />
                  </a-form-item>
                    <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                      <a-switch
                        checked-children="是" 
                        un-checked-children="否"                   
                        v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
                        />
                    </a-form-item>
                  <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol"  extra="仅后厨制作单支持使用"> 
                      <a-switch
                        checked-children="是" 
                        un-checked-children="否"                   
                        v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
                        />
                  </a-form-item>
                  <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                    <a-switch
                      checked-children="是" 
                      un-checked-children="否"                   
                      v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                      />
                  </a-form-item> -->
                  <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
                    <a-input-number
                      :min="0"
                      v-decorator="['print_times', { rules: [{required: true, message: '请输入打印联数'}]}]"
                      :disabled="disabled"
                    />
                  </a-form-item>       
          </div>
          <div v-if=" printerTypeID==='1'">
            <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
              <a-radio-group
                v-decorator="['printer_category', { rules: [{required: true,message: '请输入打印种类'}]}]"
              >
                <a-radio
                  v-for="(item, index) in PrinterCategory"
                  :key="index"
                  :value="item.value"
                >
                {{ item.name }}           
            </a-radio>
              </a-radio-group>
            </a-form-item>
           <a-form-item
            label="IP地址"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"        
            >
              <a-input v-decorator="['printer_config.address', {rules: [{required: true,message: '请选择IP地址'}]}]" />
          </a-form-item>
           <a-form-item label="端口号" :labelCol="labelCol" :wrapperCol="wrapperCol">
           <a-select style="width: 120px" v-decorator="['printer_config.prot', {initialValue:'请选择',rules: [{required: true,message: '请选择端口号'}]}]" >
            <a-select-option value=9100>
            9100
            </a-select-option>
             <a-select-option value=9500>
            9500
            </a-select-option>           
          </a-select>        
        </a-form-item>
         <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
           <a-select  style="width: 120px" v-decorator="['width', {initialValue:'请选择',rules: [{required: true,message: '请选择纸张类型'}]}]">
             <a-select-option value=58>
              58mm
            </a-select-option>
             <a-select-option value=80>
              80mm
            </a-select-option>
          </a-select>
        </a-form-item>
         <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
          <a-switch
           checked-children="是" 
           un-checked-children="否"           
           v-decorator="['status',{initialValue:'checked',valuePropName: 'checked' }]"   
             />
         </a-form-item>
           <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
              <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
              />
            </a-form-item>
          <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol"  extra="仅后厨制作单支持使用"> 
            <a-switch
              checked-children="是" 
              un-checked-children="否"                   
              v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
            />
            </a-form-item>
             <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
              <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                />
            </a-form-item> -->
         <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
          <a-input-number
            :min="0"
            v-decorator="['print_times', { rules: [{required: true, message: '请输入打印联数'}]}]"
            :disabled="disabled"
          />
        </a-form-item>   
        </div>
         <div v-if="printerTypeID==='2' ">
        <!-- <a-form-item label="打印机名称" :labelCol="labelCol" :wrapperCol="wrapperCol"  v-if="printerTypeID==='2' || printerTypeID==='3'"> -->
          <a-form-item label="打印机品牌"  :labelCol="labelCol" :wrapperCol="wrapperCol" >
         <!-- <a-radio-group
            v-decorator="['printer_type', {initialValue: PrinterEnum.FEI_E_YUN.value, rules: [{required: true}]}]"
          > -->
          <a-radio-group  
          @change="getRadioVal"
          v-decorator="['printer_config.brand', {rules: [{required: true,message: '请选择打印机品牌'}]}]"
         
            >
            <a-radio
              v-for="(item, index) in filterPrinterTypeName()"
              :key="index"
              :value="item.id"
                                             
            >
             {{ item.name }}
           </a-radio>
           </a-radio-group>
          <!-- </a-radio-group> -->
        </a-form-item>

        <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-radio-group
            v-decorator="['printer_category', { rules: [{required: true,message: '请输入打印种类'}]}]"
          >
            <a-radio
              v-for="(item, index) in PrinterCategory"
              :key="index"
              :value="item.value"
            >
            {{ item.name }}
           
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <div
         v-show="radioVal===1"        
        >
          <a-form-item
            label="USER"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="飞鹅云后台注册用户名"
          >
            <a-input v-decorator="['printer_config.USER', {rules: [{required: true,message: '请填写USER'}]}]" />
          </a-form-item>
          <a-form-item
            label="UKEY"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="飞鹅云后台登录生成的UKEY"
          >
            <a-input v-decorator="['printer_config.UKEY', {rules: [{required: true,message: '请填写UKEY'}]}]" />
          </a-form-item>
          <a-form-item
            label="打印机编号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="打印机编号为9位数字，查看飞鹅打印机底部贴纸上面的编号"
          >
            <a-input v-decorator="['printer_config.SN', {rules: [{required: true,message: '请填写打印机编号'}]}]" />
          </a-form-item>
        </div>
        <!-- 芯烨打印机 -->
        <div
          v-show="radioVal===0"
         
        >
          <a-form-item
            label="USER"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="芯烨云后台注册用户名"
          >
            <a-input v-decorator="['printer_config.USER', {rules: [{required: true,message: '请填写UKEY'}]}]" />
          </a-form-item>
          <a-form-item
            label="UKEY"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="芯烨后台登录生成的UKEY"
          >
            <a-input v-decorator="['printer_config.UKEY', {rules: [{required: true,message: '请填写UKEY'}]}]" />
          </a-form-item>
          <a-form-item
            label="打印机编号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            extra="打印机编号为9位数字，查看飞鹅打印机底部贴纸上面的编号"
          >
            <a-input v-decorator="['printer_config.SN', {rules: [{required: true,message: '请填写打印机编号'}]}]" />
          </a-form-item>
        </div>     
         <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
           <a-select  style="width: 120px" v-decorator="['width', {initialValue:'请选择',rules: [{required: true,message: '请选择纸张类型'}]}]">
             <a-select-option value=58>
              58mm
            </a-select-option>
             <a-select-option value=80>
              80mm
            </a-select-option>
          </a-select>
          <!-- <a-input
            v-decorator="['printer_name', {rules: [{required: true, min: 2, message: '请输入至少2个字符'}]}]"
          /> -->
          
        </a-form-item>
         <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
          <a-switch
           checked-children="是" 
           un-checked-children="否"           
           v-decorator="['status',{initialValue:'checked',valuePropName: 'checked' }]"            
             />
         </a-form-item>
          <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
              <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
              />
          </a-form-item>
          <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol"  extra="仅后厨制作单支持使用"> 
            <a-switch
              checked-children="是" 
              un-checked-children="否"                   
              v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
              />
          </a-form-item>
           <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
              <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                />
            </a-form-item> -->
        <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
          <a-input-number
            :min="0"
            v-decorator="['print_times', { rules: [{required: true, message: '请输入打印联数'}]}]"
            :disabled="disabled"
          />
        </a-form-item>
        
        </div>
      </div>
        <div class="totalright">      
       <a-form-item label="单据列表">
      <a-checkbox-group
        v-decorator="['voucher_list',{rules:[{required: true,message: '请选择单据列表'}]}]"       
        style="width: 100%;"
      >
          <a-row>
             <a-col v-for="(item) in choseType" :key="item.id">
              <a-checkbox :value="item.id">
                {{item.receipt_name}}
              </a-checkbox>
            </a-col>
          </a-row>        
          </a-checkbox-group>
       </a-form-item>  
    </div>
        </a-form> 
     </a-spin>

   </div>
   <template slot="footer">
      <div class="searchForm">
        <div class="buttonxin">
          <a-button class="cancleBtn btn" @click="testButton">测试打印机</a-button>
          <a-button class="searchBtn btn" @click="clickGuanLian">关联商品</a-button>
        </div>
        <div>
          <a-button class="cancleBtn" @click="handleCancel">取消</a-button>
          <a-button class="searchBtn" @click="handleSubmit">确定</a-button>
        </div>
      </div>
    </template>
    
    <RefSelectCheckbox  :show="visibleGuan"  :state="printer_id"  @func="changemodal"/>
   </a-modal>
</template>
<script>
import {getReceiptList,newedit} from '@/request/api';
import RefSelectCheckbox from './RefSelectCheckbox.vue';
import _ from 'lodash';
export default {
  props:['everyList','bian'],
  data() {
    return {
      confirmLoading:false,
      form: this.$form.createForm(this), //当前表单元素
      labelCol: { span: 3 }, // 输入框布局属性
      wrapperCol: { span: 10 },
      printerTypeID:'0',//打印机类型选中id 0本地打印机 1 网络打印机 2 云打印机
      PrinterCategory:[
        { key: 'xiaopiao', name: '小票', value: 0 },
        { key: 'biaoqian', name: '标签', value: 1 }
      ],
      PrinterEnum:[     
      { key: 'PRINT_RUI_YE', name: '芯烨云打印', value: 'PRINT_RUI_YE' },
       { key: 'FEI_E_YUN', name: '飞鹅打印机', value: 'FEI_E_YUN' }
    ],
    choseType:[],//单据列表数组
    radioVal:0,
    record: {},
    visibleGuan:false,//是否显示关联商品对话框
    printer_id:'',//打印机printer_id
    printList:[],//打印机列表 
    disabled: true,
    };
  },
  components: {
    RefSelectCheckbox 
  },
  created() {    
  },
  mounted(){
    this.getPiaoList();
    this.printList= JSON.parse(bound.showPrintList());
  },
  updated(){
      //  this.form.validateFields((err, values) => {
      //      console.log(JSON.stringify(values))
      //    })
  },
  computed: {},
  watch: {
     bian: {
         handler: function() {
            this.record=this.everyList; 
            console.log(JSON.stringify( this.record))
            this.printer_id=this.everyList.printer_id;
            if(this.record.printer_type==='本地打印机'){
            this.printerTypeID='0'
          }else if(this.record.printer_type==='网络打印机'){
            this.printerTypeID='1'
          }else if(this.record.printer_type==='云打印机'){
            this.printerTypeID='2'
          }
          if(this.record.printer_config.brand ===0){
              this.radioVal=0
          }else if(this.record.printer_config.brand ===1){
              this.radioVal=1
          }
            if(this.record.status ===0){
            this.record.status=true;
          }else if(this.record.status ===1){
            this.record.status=false;
          }
           if(this.record.only_cut ===2){
            this.record.only_cut=true;
          }else if(this.record.only_cut ===1){
            this.record.only_cut=false;
          }
           if(this.record.is_order_printer ===2){
            this.record.is_order_printer=true;
          }else if(this.record.is_order_printer ===1){
            this.record.is_order_printer=false;
          }
            const { form: { setFieldsValue } } = this;  
            console.log(JSON.stringify(this.record)) 
            this.$nextTick(() => {
              setFieldsValue({             
                // status:true,
              })
              this.$nextTick(() => {
                 setFieldsValue(_.pick(this.record, ['custom_name','only_cut','status','printer_name', 'printer_type', 'printer_category', 'print_times', 'sort','width','shop_id','voucher_list','printer_config.prot','printer_config.address','printer_config.SN','printer_config.UKEY','printer_config.USER','printer_config.brand','is_order_printer']))
              })
          this.$nextTick(() => {
            setFieldsValue(_.pick(this.record, ['printer_config']))
          })
        })        
         },
         deep: true
     }
},
 methods:{
   handleChange(value){    
      this.printerTypeID=value;
    },
     getPiaoList(){  //单据列表数据请求
      getReceiptList()
            .then((res) => {
               const newchoseType=[];
              if(res.status===200){
               
                for(let i=0;i<res.data.data.list.length;i++){
                   res.data.data.list[i].state=false;
                    newchoseType.push(res.data.data.list[i])
                }
             this.choseType=newchoseType;  
              }                        
            })
            .catch((error) => {
              return false
            });
  },
   filterPrinterTypeName(){
      const oldArr=this.PrinterEnum;
      const newArr=oldArr.filter(function(item){
           return item.name !== '365云打印'         
   });
   for(let i=0;i<newArr.length;i++){
     if(newArr[i].key ==="FEI_E_YUN"){
       newArr[i].id=1
     }else if(newArr[i].key ==="PRINT_RUI_YE"){
        newArr[i].id=0
     }     
   } 
   return newArr     
    },
  getRadioVal(e){
   this.radioVal=e.target.value;
 },
 handleSubmit(){  //点击modal框确认按钮
  this.form.validateFields((errors, values) => {
    console.log(JSON.stringify(values))
     !errors && this.onFormSubmit(values)
  })
 },
  handleCancel () { //关闭对话框
  //console.log(this.printerTypeID)
      this.$emit('fun', false)
    },
 onFormSubmit(values){   //提交input数据给后台
   newedit({ printerId: this.record.printer_id, form: values })
            .then((res) => {
              if(res.status===200){
                 this.$message.success('更新成功', 1.5);//显示更新成功
                 this.handleCancel();//关闭对话框
                  this.$emit('handleSubmit', values)//通知父组件更新数据完成
              }            
            })
            .catch(result => {
             return false;
            })
 },
 changemodal(data){
     this.visibleGuan=data;
   },
 clickGuanLian(){
     this.visibleGuan=true;
   },  
  testButton(){//测试打印机按钮
  const str={
    "key": "print",
    "print_name": "local",
    "type": "test",
    "content_list": [
    {"val":"打印机名称", "type": "text",  "size":10, "lineHeight": 26}, 
    {"val":"    测试打印机", "type": "text",  "size":12, "lineHeight": 26}, 
    {"val":"   (打印成功)", "type": "text",  "size":14, "lineHeight": 30}, 
    {"val":"欢迎使用鑫易刷系统 ", "type": "text",  "size":8, "lineHeight": 26}, 
    {"val":"2010-01-20 12:00:00:45 ", "type": "text", "size":8, "lineHeight": 26},
    ]
  };
  str.print_name=this.record.printer_name;
   this.form.validateFields((errors, values) => {
      str.print_name=values.printer_name;
      str.content_list[0].val="打印机："+values.printer_name;
        // console.log(JSON.stringify(values))
  })
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth()+1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
  const gettime ='打印时间'+" "+ yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
  str.content_list[4].val=gettime;
  
  console.log( JSON.stringify(gettime))
  console.log( JSON.stringify(str))
  
   bound.printListBtn(JSON.stringify(str))
  },
 },
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:50vw !important;
  .ant-modal-header{
    padding:1.5vh 1.5Vw;
    background:rgba(237, 238, 242, 1);
    .ant-modal-title{
      font-size: 2vh ;
      font-weight: 600;
    }
  }
  .ant-modal-body{
    // padding:1.5vw;
    .total{
      width:46vw;
      overflow: hidden;
      .ant-form{
        label{
          font-size: 1.8vh;
        }
      } 
      .ant-form-item{
        margin-bottom: 2vh;
      }
      .totalleft{
        float:left;
       width:25vw;     
        
      }
      .totalright{
        float:right;
        width:20vw;
       }
      .ant-col-10{
        margin-left:0.3vw;
        width:15vw;
      }
       .ant-form-item-label{
         width:8.2vw;
       }     
    }    
  }  
}
.totalright{
  /deep/.ant-form-item-control{
    height:40vh ;  
    background-color: white !important;  
    border:1px solid rgba(204, 204, 204, 1);
    overflow-y:auto;
    border-radius: 4px;
    .ant-col{
      line-height: 3.5vh;
      color:rgba(101, 97, 101, 1);
      border-radius: 4px;      
      text-align: center;
      margin-top: 1.3vh;
      border-radius: 4px;
    }
  }
}
 /deep/.ant-checkbox-wrapper{
    border:1px solid rgba(204, 204, 204, 1);
   height:3.5vh;
    border-radius: 4px;
  
 }
 /deep/.ant-checkbox-inner{
   display: none;
 }
 
  /deep/.ant-form-item label{
    width:auto !important;
  }
   /deep/.totalright  .ant-form-item label{
    width:15vw !important;
  }

  /deep/.ant-btn{
    height:4vh;
    padding:0 20px;
    font-size: 1.8vh;
  }
  .searchForm{
    display: flex;
    justify-content: space-between;
    .buttonxin{
      .btn{
        width: 9vw;
        padding:0 1vw;
      }
      
    }
  }
  
   /deep/.ant-modal-body {
    padding-top:1.5vw;
    padding-bottom: 1.5vw;
  }
  /deep/.ant-modal-content{
      background:rgba(237, 238, 242, 1);
  }
  /deep/.ant-input {
    width:150px;
    font-size: 1.8vh;
  }
 /deep/.ant-select-selection-selected-value{
   font-size: 1.8vh;
 }
   /deep/.ant-select{
   width:150px !important;
 }
  /deep/ .ant-select-selection__placeholder{
     font-size: 1.8vh;
  }
  /deep/.ant-input:hover {
    border-color: #cccccc !important;
  }
  /deep/.ant-select-selection:hover{
    border-color: #cccccc !important;
  }

</style>

