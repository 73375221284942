<template>
  <a-modal 
    title="商品关联关系" 
    :width="720" 
    :visible="show"
    @ok="handleConfirmClick"
     @cancel="handleCancel"
      ok-text="确认" 
     cancel-text="取消" 
  >
    <template slot="footer">
      <div class="searchForm" >
        <a-button class="cancleBtn" @click="handleCancel">取消</a-button>
        <a-button class="searchBtn" @click="handleConfirmClick">确定</a-button>
      </div>
    </template>
    <div class="main_container myForm">
      <div class="top">
        <div>已选商品数量: &nbsp;<span class="textColor">{{goods.length}}</span></div>
        <a-checkbox :checked="checkAll" @change="handleAllCheckedClick">全选</a-checkbox>
      </div>
      <div class="content">
        <div v-for="(item, index) in listData" :key="index">
          <div class="item" @click="handleExpandClick(item)">
            <a-checkbox 
              :checked="item.checked"
              :key="index" 
              @change="handleCheckboxChange(item)"
            > 
              {{ item.name }}
            </a-checkbox>
            <a-icon :type="item.expand ? 'up' : 'down'"  />
          </div>
          <div class="sub_item" v-show="item.expand">
            <template v-for="(subItem, subIndex) in item.goods">
              <a-checkbox 
                :checked="subItem.checked"
                :key="subIndex" 
                @change="handleCheckboxChange(subItem)"
              > 
                {{ subItem.goods_name }}
              </a-checkbox>
            </template>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {goodsListRequest,PrinterGoods,getGoods} from '@/request/api';
export default {
  props:['show','state'],
  data() {
    return {
      listData: [],
      checkAll: false,
      goods:[],//选中的商品的列表id
      modalShow:true,
      checkNumber:0,
    }
  },
  mounted() {
    // this.loadList();
  },
  updated(){
  },
  watch: {
    listData: {
      handler(newValue) {
        newValue.map(v => {
          v.checked = v.goods.every(e => e.checked);
        })
        this.checkAll = newValue.every(v => v.checked);
        const data=this.listData;
        const arr=[];
        for(let i=0;i<data.length;i++){
          const goods=data[i].goods;
          for(let j=0;j<goods.length;j++){
              if(goods[j].checked){
                arr.push(goods[j].goods_id)
              }
          }   
        }     
        this.goods=arr;
      },
      deep: true
    },
     show:{
      handler(value){    
        if(this.show===true){
           this.loadList();
        }
      },
      deep:true,
    }
  },
  methods: {
    loadList() {   
      goodsListRequest().then(res => {
        const {
          status
        } = res
        const list =res.data.data.list;
       //console.log(JSON.stringify(list))
        if (status === 200) {
          list.map(item => {
            item.expand = true;
            item.checked = false;
            item.goods.map(subItem => {
              subItem.checked = false;
            })
          })
          this.listData = list;
          this.goodsRequest();
        }
      })
    },
    // 展开/收起
    handleExpandClick(item) {
      item.expand = !item.expand;
    },
    // 全选点击事件
    handleAllCheckedClick() {
      this.checkAll = !this.checkAll;
      this.listData.map(item => {
        item.checked = this.checkAll;
        item.expand = true;
        item.goods.map(subItem => {
          subItem.checked = this.checkAll;
        })
      })
    },
    // 多选框改变
    handleCheckboxChange(item) {
      item.checked = !item.checked;
      item.expand = true;
      this.handleSubItemChecked(item);
    },
    // 处理二级菜单
    handleSubItemChecked(item){
      item && item.goods && item.goods.map(v => {
        v.checked = item.checked;
      })
    },
    // 处理数据
    processCheckedData(data) {
      let arr = [];
      data && data.map(item => {
        if(item.checked){
          item.goods.map(v => arr.push(v.goods_id));
        }else{
          item.goods.filter(i => i.checked).map(v => arr.push(v.goods_id))
        }
      })
      return arr;
    },
    // 确定按钮事件
    handleConfirmClick(){
      //console.log(this.processCheckedData(this.listData))
      this.guanLianClick()
    },
    guanLianClick(){
      const object={};
      object.printer_id=this.state;
      object.goods=this.goods;
      const _this=this;
      PrinterGoods(object).then(res => {     
        const {status}=res;
      // console.log(JSON.stringify(status))
        if (status === 200) {
          _this.modalShow=false;
          _this.$emit('func',_this.modalShow)       
        }
      })
    },
    handleCancel(){
       this.$emit('func',false)
    },
    goodsRequest(){
      const _this=this;      
      getGoods({printerId:this.state}).then(res => {      
        const {status}=res;
        if (status === 200) {
          _this.goods=res.data.data && res.data.data;
          console.log(JSON.stringify( _this.goods))
          for(let i=0;i<_this.listData.length;i++){
          for(let j=0;j<_this.listData[i].goods.length;j++){
            if(_this.goods.indexOf(_this.listData[i].goods[j].goods_id)!==-1){
                _this.listData[i].goods[j].checked=true;
            }
          }
        }
        }
      }).catch(err=>{
        return false;
      })      
    }
  }, 
}
</script>
<style lang="less" scoped>
.main_container {
  font-size: 1.8vh;
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom:1vh;
  }
  .ant-checkbox-wrapper {
    border: none !important;
    height: auto !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: 1.8vh;
  }
  /deep/.ant-checkbox-inner {
    display: block !important;
  }
  .content {
    position: relative;
    height: 60vh;
    overflow: auto;
    background-color: white;
    font-size: 1.8vh;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border: 1px solid #d0d0d0;
      width: 100%;
      height: 4.5vh;
      font-size: 1.8vh;
    }
    .sub_item{
      padding-left: 46px;
   .ant-checkbox-wrapper{
        padding-top: 14px !important;
        width:12vw;
      }
    }
  }
    .ant-checkbox-wrapper-checked{
      background: none !important;
      color: inherit !important;
    }
  //  /deep/.ant-checkbox-checked{
  //     .ant-checkbox-inner{
  //       background:rgba(255, 103, 0, 1) ;
  //       border-color:rgba(255, 103, 0, 1)  ;
  //     }

  //   } 
}
 /deep/.ant-checkbox-inner:hover{
    // border-color: black !important;
  }
 
</style>
