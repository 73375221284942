<template>
  <a-modal
    title="新增打印机"
    :width="720"
    :visible="zeng"
     @cancel="handleCancel"
     ok-text="确认" 
     cancel-text="取消" 
  >
    <template slot="footer">
      <div class="searchForm" >
        <a-button class="cancleBtn" @click="handleCancel">取消</a-button>
        <a-button class="searchBtn" @click="handleSubmit">确定</a-button>
      </div>
    </template>
    <div class="total myForm">
      <a-spin :spinning="confirmLoading">
        <a-form :form="form" autocomplete="off">
          <div class="totalleft">
            <a-form-item label="打印机名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input
                v-decorator="['custom_name', {rules: [{required: true,  message: '请输入至少2个字符'}]}]"
              />
            </a-form-item>
            <a-form-item label="打印机" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select           
                  style="width: 120px"
                  v-decorator="[
                    'printer_name',
                    { rules: [{ required: false, message: '请选择打印机'}] },
                  ]"
                  placeholder="请选择"
                >
                    <a-select-option
                      :value="item.name"
                      v-for="(item,index) in printList"
                      :key="index"
                    >
                      {{item.name}}
                    </a-select-option>
                    
                </a-select>
            </a-form-item>
            <a-form-item label="打印机类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select           
                style="width: 120px"
                @change="handleChange"
                v-decorator="[
                  'printer_type',
                  { rules: [{ required: true, message: '请输入打印机类型'}] },
                ]"
                placeholder="请选择"
              >
                  <a-select-option value="0">
                    本地打印机
                  </a-select-option>
                  <a-select-option value="1">
                    网络打印机
                  </a-select-option>
                  <!-- <a-select-option value="2">             
                    云打印机
                  </a-select-option> -->
              </a-select>
            </a-form-item>
            <div v-if=" printerTypeID==='0'">
              <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-radio-group
                  v-decorator="['printer_category', { rules: [{required: true,message: '请选择打印种类'}]}]"
                >
                  <a-radio
                    v-for="(item, index) in PrinterCategory"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}           
                  </a-radio>
                </a-radio-group>       
              </a-form-item> 
              <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select style="width: 120px" v-decorator="['width', {rules: [{required: true,message: '请选择纸张类型'}]}]"  placeholder="请选择">
                  <a-select-option value=58>
                    58mm
                  </a-select-option>
                  <a-select-option value=80>
                    80mm
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                  v-decorator="['status',{initialValue:true,valuePropName: 'checked'}]"           
                  />
              </a-form-item>
              <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item>
              <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="仅限后厨制作单支持使用"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item>
              <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item> -->
              <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
                  <a-input-number
                    :min="0"
                    v-decorator="['print_times', { initialValue :1, rules: [{required: true,message: '请输入打印联数'}]}]"
                    :disabled="disabled"
                  />
              </a-form-item>        
            </div>
            <div v-if=" printerTypeID==='1'">
              <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-radio-group
                  v-decorator="['printer_category', { rules: [{required: true,message: '请输入打印种类'}]}]"
                >
                  <a-radio
                    v-for="(item, index) in PrinterCategory"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}                 
                  </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                label="IP地址"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"        
              >
                <a-input v-decorator="['printer_config.address', {rules: [{required: true,message: '请选择IP地址'}]}]" />
              </a-form-item>
              <a-form-item label="端口号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select style="width: 120px" v-decorator="['printer_config.prot', {rules: [{required: true,message: '请选择端口号'}]}]"  placeholder="请选择端口号">
                  <a-select-option value=9100>
                  9100
                  </a-select-option>
                  <a-select-option value=9500>
                  9500
                  </a-select-option>            
                </a-select>       
              </a-form-item>
              <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select  style="width: 120px" v-decorator="['width', {rules: [{required: true,message: '请选择纸张类型'}]}]" placeholder="请选择">
                  <a-select-option value=58>
                    58mm
                  </a-select-option>
                  <a-select-option value=80>
                    80mm
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                checked-children="是" 
                un-checked-children="否"           
                v-decorator="['status',{initialValue:true,valuePropName: 'checked' }]"            
                  />
              </a-form-item>
              <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item>
              <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="仅限后厨制作单支持使用"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item>
              <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item> -->
              <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
                <a-input-number
                  :min="0"
                  v-decorator="['print_times', {initialValue :1, rules: [{required: true, message: '请输入打印联数'}]}]"
                  initialValue ="1"
                  :disabled="disabled"
                />
              </a-form-item>       
            </div>
            <div v-if="printerTypeID==='2' ">
              <!-- <a-form-item label="打印机名称" :labelCol="labelCol" :wrapperCol="wrapperCol"  v-if="printerTypeID==='2' || printerTypeID==='3'"> -->
              <a-form-item label="打印机品牌"  :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <!-- <a-radio-group
                    v-decorator="['printer_type', {initialValue: PrinterEnum.FEI_E_YUN.value, rules: [{required: true}]}]"
                  > -->
                <a-radio-group  
                  @change="getRadioVal"
                  v-decorator="['printer_config.brand', {rules: [{required: true,message: '请选择打印机品牌'}]}]"         
                  >
                  <a-radio
                    v-for="(item, index) in filterPrinterTypeName()"
                    :key="index"
                    :value="item.id"                               
                  >
                    {{ item.name }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="打印种类" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-radio-group
                  v-decorator="['printer_category', { rules: [{required: true,message: '请输入打印种类'}]}]"
                >
                  <a-radio
                    v-for="(item, index) in PrinterCategory"
                    :key="index"
                    :value="item.value"
                  >
                  {{ item.name }}       
                  </a-radio>
                </a-radio-group>
              </a-form-item>      
              <div v-show="radioVal===1">
                <a-form-item
                  label="USER"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="飞鹅云后台注册用户名"
                >
                  <a-input v-decorator="['printer_config.USER', {rules: [{required: true,message: '请填写USER'}]}]" />
                </a-form-item>
                <a-form-item
                  label="UKEY"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="飞鹅云后台登录生成的UKEY"
                >
                  <a-input v-decorator="['printer_config.UKEY', {rules: [{required: true,message: '请填写UKEY'}]}]" />
                </a-form-item>
                <a-form-item
                  label="打印机编号"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="打印机编号为9位数字，查看飞鹅打印机底部贴纸上面的编号"
                >
                  <a-input v-decorator="['printer_config.SN', {rules: [{required: true,message: '请填写打印机编号'}]}]" />
                </a-form-item>
              </div>
              <!-- 芯烨打印机 -->
              <div v-show="radioVal===0">
                <a-form-item
                  label="USER"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="芯烨云后台注册用户名"
                >
                  <a-input v-decorator="['printer_config.USER', {rules: [{required: true,message: '请填写UKEY'}]}]" />
                </a-form-item>
                <a-form-item
                  label="UKEY"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="芯烨后台登录生成的UKEY"
                >
                  <a-input v-decorator="['printer_config.UKEY', {rules: [{required: true,message: '请填写UKEY'}]}]" />
                </a-form-item>
                <a-form-item
                  label="打印机编号"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  extra="打印机编号为9位数字，查看飞鹅打印机底部贴纸上面的编号"
                >
                  <a-input v-decorator="['printer_config.SN', {rules: [{required: true,message: '请填写打印机编号'}]}]" />
                </a-form-item>
              </div>     
              <a-form-item label="纸张类型" :labelCol="labelCol" :wrapperCol="wrapperCol"  >
                <a-select  style="width: 120px" v-decorator="['width', {rules: [{required: true,message: '请选择纸张类型'}]}]" placeholder="请选择">
                <a-select-option value=58>
                  58mm
                  </a-select-option>
                  <a-select-option value=80>
                    80mm
                  </a-select-option>
                </a-select>
                <!-- <a-input
                  v-decorator="['printer_name', {rules: [{required: true, min: 2, message: '请输入至少2个字符'}]}]"
                /> -->
              
              </a-form-item>
              <a-form-item label="是否可用" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                checked-children="是" 
                un-checked-children="否"                   
                    v-decorator="['status',{ initialValue:true,valuePropName: 'checked',rules: [{required: true, message: '请选择是否可用'}]}]"          
                  />
                <a-form-item label="接单打印机" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                  <a-switch
                    checked-children="是" 
                    un-checked-children="否"                   
                    v-decorator="['is_order_printer',{ valuePropName: 'checked',initialValue:false, }]"          
                    />
                </a-form-item>
              </a-form-item>
              <a-form-item label="一菜一切" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="仅限后厨制作单支持使用"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['only_cut',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item>
              <!-- <a-form-item label="后厨制作" :labelCol="labelCol" :wrapperCol="wrapperCol"> 
                <a-switch
                  checked-children="是" 
                  un-checked-children="否"                   
                  v-decorator="['printer_config.HC',{ valuePropName: 'checked',initialValue:false, }]"          
                  />
              </a-form-item> -->
              <a-form-item label="打印联数" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="同一订单，打印的次数"   >
                <a-input-number
                  :min="0"
                  v-decorator="['print_times', {initialValue :1, rules: [{required: true, message: '请输入打印联数'}]}]"
                :disabled="disabled"
                />
              </a-form-item>        
            </div>
          </div>
          <div class="totalright">
            <a-form-item label="单据列表">
              <a-checkbox-group
                v-decorator="['voucher_list',{rules:[{required: true,message: '请选择单据列表'}]}]"       
                style="width: 100%;"
              >
              <a-row>
                <a-col v-for="(item) in choseType" :key="item.id">
                  <a-checkbox :value="item.id">
                    {{item.receipt_name}}
                  </a-checkbox>
                </a-col>
              </a-row>          
              </a-checkbox-group>
            </a-form-item>
          </div>
        </a-form>
      </a-spin>
    </div>
  </a-modal>  
</template>
<script>
import {getReceiptList,newadd} from '@/request/api';
export default {
props:['zeng'],
data() {
return {
  visible:false,//是否显示对话框 
  labelCol: { span: 3 }, // 标签布局属性     
  wrapperCol: { span: 10 }, // 输入框布局属性
  confirmLoading:false,
  form: this.$form.createForm(this),//创建表单当前元素
  printerTypeID:'0',
  PrinterCategory:[
    { key: 'xiaopiao', name: '小票', value: 0 },
    { key: 'biaoqian', name: '标签', value: 1 }
  ],
  PrinterEnum:[  
  { key: 'PRINT_RUI_YE', name: '芯烨云打印', value: 'PRINT_RUI_YE' },
  { key: 'FEI_E_YUN', name: '飞鹅打印机', value: 'FEI_E_YUN' },
],
 choseType:[],//单据列表数据
radioVal:'飞鹅打印机',
inputMessage:{},//  input框提交的form信息
radioVal:0, 
printList:[],//打印机列表 
disabled: true,
}
},
 watch:{
    zeng:{
      handler(){
        if(this.zeng){
          const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.handleSubmit()
            } 
          }    
        }
       
      },
      deep:true,
    }
  },
created() {},
mounted(){
  this.getPiaoList();
  this.printList= JSON.parse(bound.showPrintList());
},
updated(){   
},
computed: {    
},
methods: { 
  handleChange(value){    
    this.printerTypeID=value;
  },
  getPiaoList(){  //单据列表数据请求
      getReceiptList()
            .then((res) => {
               const newchoseType=[];
              if(res.status===200){             
                for(let i=0;i<res.data.data.list.length;i++){
                   res.data.data.list[i].state=false;
                    newchoseType.push(res.data.data.list[i])
                }
             this.choseType=newchoseType;  
             //console.log(JSON.stringify(this.choseType))
              }                        
            })
            .catch((error) => {
              return false
            });
  },
  handleSubmit(){  //点击modal框确定按钮 
   //this.form.setFieldsValue({"printer_config":'{}'})
     
      // this.$nextTick(() => {
    
      // })
  this.form.validateFields((errors, values) => {
    // console.log(JSON.stringify(values))
     !errors && this.onFormSubmit(values)
  })
  },
  handleCancel () { //关闭对话框
      this.printerTypeID='0';
      this.form.resetFields(); //清除书写框
      //  const { form: { setFieldsValue } } = this
      // this.$nextTick(() => {
      //   setFieldsValue({
      //     status:true,
      //   })
      // })

      this.$emit('funcs', false)
    },
  onFormSubmit(values){   //提交form列表给后台 
  //this.confirmLoading = true; 
    // console.log(JSON.stringify({form:values}))
  newadd({form:values})
        .then((result) => {
          this.inputMessage=values;           
          // 显示成功
           //console.log(JSON.stringify(result.message));   添加成功   
           if(result.data.status==200){
             this.$message.success(result.data.message, 1.5)
           }       
      //        const { form: { setFieldsValue } } = this
      // this.$nextTick(() => {
      //   setFieldsValue({
      //     status:true,
      //   })
      // })

          // // 关闭对话框事件
          this.handleCancel()
          // // 通知父端组件提交完成了
          this.$emit('handleSubmit', values)
         
        })
        .catch(() => {
          this.confirmLoading = false
        })
  },
   filterPrinterTypeName(){
      const oldArr=this.PrinterEnum;
      const newArr=oldArr.filter(function(item){
           return item.name !== '365云打印'         
   });
   for(let i=0;i<newArr.length;i++){
     if(newArr[i].key ==="FEI_E_YUN"){
       newArr[i].id=1
     }else if(newArr[i].key ==="PRINT_RUI_YE"){
        newArr[i].id=0
     }     
   }  
   return newArr     
},
getRadioVal(e){
   this.radioVal=e.target.value;
 },
},  
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:50vw !important;
  .ant-modal-header{
    padding:1.5vh 1.5Vw;
    background:rgba(237, 238, 242, 1);
    .ant-modal-title{
      font-size: 2vh ;
      font-weight: 600;
    }
  }
  .ant-modal-body{
    .total{
      width:46vw;
      overflow: hidden;
      .ant-form{
        label{
          font-size: 1.8vh;
        }
      } 
      .ant-form-item{
        margin-bottom: 2vh;
      }
      .totalleft{
        float:left;
        width:25vw;       
      }
      .totalright{
        float:right;
        width:20vw;
       }
      .ant-col-10{
        margin-left:0.3vw;
        width:15vw;
      }
       .ant-form-item-label{
         width:8.2vw;
       }     
    }    
  }  
}
.totalright{
  /deep/.ant-form-item-control{
    height:40vh ;  
    background-color: white !important;  
    border:1px solid rgba(204, 204, 204, 1);
    overflow-y:auto;
    border-radius: 4px;
    .ant-col{
      line-height: 3.5vh;
      color:rgba(101, 97, 101, 1);
      border-radius: 4px;      
      text-align: center;
      margin-top: 1.3vh;
      border-radius: 4px;
    }
  }
}
   
 /deep/.ant-checkbox-wrapper{
    border:1px solid rgba(204, 204, 204, 1);
   height:3.5vh;
    border-radius: 4px; 
 }
 /deep/.ant-checkbox-inner{
   display: none;
 } 
  /deep/.ant-form-item label{
    width:auto !important;
  }
   /deep/.totalright  .ant-form-item label{
    width:15vw !important;
  }
  /deep/.ant-modal-body {
    padding-top:1.5vw;
  }
  /deep/.ant-modal-content{
    background:rgba(237, 238, 242, 1);
  }
  /deep/.ant-input {
     width:150px!important;
    font-size: 1.8vh;
  }
 /deep/.ant-select-selection-selected-value{
   font-size: 1.8vh;
 }
 /deep/.ant-select{
   width:150px !important;
 }
 /deep/ .ant-select-selection__placeholder{
     font-size: 1.8vh;
  }
  /deep/.ant-input:hover {
    border-color: #cccccc !important;
  }
  /deep/.ant-select-selection:hover{
    border-color: #cccccc !important;
  }
  /deep/.ant-btn{
    font-size: 1.8vh;
    height: 3.9vh;
  }
</style>
